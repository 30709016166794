@import "../../css/_general_styles.scss";

.footer{
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    background-color: black;
    .rrssContainer{
        margin-top: 2rem!important;
        margin-bottom: 3rem!important;
    }
}

.rrssImg{
    a:hover{
        border: none!important
    }
}
.footerSpan{
    @include primary-text(1rem, $white, 400) ;
}

.logoFooter{
    width: 15rem;
}

.logoFooterContainer{
    margin-bottom: 2rem;
}