// Colors

$primary-color: #0C0D15;
$secondary-color: #333;
$green: #30CC47;

$black: #333;
$white: #fff;
$grey: #C4C4C4;
$dark-grey: #353535;

// Typographic Scale
$title: 28px;
$subtitle: 18px;
$header: 112px;
$h1: 18px;
$h2: 16px;
$h3: 14px;
$button: 12px;
$body: 12px;
$tabs: 10px;
$label: 12px;

// Shadow
$shadow: 0 1px 6px rgba(147, 141, 158, 0.16), 0 2px 6px rgba(52, 53, 62, 0.16);
$shadow-header: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
$shadow-blue: 0 1px 6px rgba(1, 108, 165, 0.16), 0 2px 6px rgba(1, 108, 165, 0.16);

// Fonts

@mixin primary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}

@mixin titles($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Ethnocentric', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}
// Container

@mixin container() {
  background: #fff;
  box-shadow: 0 1px 6px rgba(147, 141, 158, 0.16), 0 2px 6px rgba(52, 53, 62, 0.28);
  border-radius: 6px;
  padding: 1em;
}

.ant-menu{
  background-color: none!important;
}