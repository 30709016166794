@import "../../css/_general_styles.scss";

.team{
    background:  url("../../img/binary.png"), linear-gradient(180deg, #061128 0%, #000000 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode:screen;
    h2{
        @include titles(2.5rem, $white, 400) ;
        text-align: center;
    }
    padding: 3rem 2rem;
}


.ant-card-meta{
    @include primary-text(1rem, white, 400) ;
    
}
.ant-card-meta-title, .ant-card-meta-description{
    color: white!important;
}
.ant-card-body{
    text-align: center;
    background-color: grey;
    border: none;
    border-radius:  0 0 1rem 1rem;
    
}

.ant-card{
    border-radius: 1rem;
    margin: 2rem;
}

.ant-card-cover{
    img{
        border-radius: 1rem 1rem  0 0 ;
    }
}

.ant-card-bordered{
    border: none;
}

.cardsContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a{
        color: white!important
    }
    a:hover{
        border: none!important
    }
}

.titlesContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 15rem;
        padding-bottom: 1rem;
    }
}
.linksTeam{
    margin-right: .5rem;
}

@media screen and (min-width: '1920px')  {
    .titlesContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            width: 25rem;
            padding-bottom: 1rem;
        }
    }
}