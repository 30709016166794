@import "../../css/_general_styles.scss";

.home {
  padding-top: 3rem;
  background-image: url("https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/backgroundcompress.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logoImg{
    width: 30rem;
  }
  .badgesImg{
    margin: 0 1rem;
  }
}

.buttonContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
  flex-wrap: wrap;
  .buttonBlue{
    cursor: pointer;
    background: #0038FF;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#949494",endColorstr="#676767",GradientType=1);
    border-radius: 37.5px;
    margin: 1rem!important;
    width: 15rem;
    @include primary-text(1.5rem, $dark-grey, 400) ;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    p{
      margin: 0;
      color: #FFFFFF;
    }    
  }
    .buttonBlue:hover{
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#949494",endColorstr="#676767",GradientType=1);
    box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;
    border-radius: 37.5px;

    margin: 1rem!important;
    width: 15rem;
    @include primary-text(1.5rem, $dark-grey, 400) ;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    p{
      margin: 0;
    }
  }
  .divBut{
    cursor: pointer;
    background: rgb(148,148,148);
background: -moz-linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27%, rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
background: -webkit-linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27% ,rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
background: linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27%, rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#949494",endColorstr="#676767",GradientType=1);
    border-radius: 37.5px;
    margin: 1rem!important;
    width: 15rem;
    @include primary-text(1.5rem, $dark-grey, 400) ;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    p{
      margin: 0;
    }
  }
  .divBut:hover{
    background: rgb(148,148,148);
    background: -moz-linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27%, rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
    background: -webkit-linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27% ,rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
    background: linear-gradient(272deg, rgba(148,148,148,1) 4.67%, rgba(224,224,224,1) 37.33%, rgba(169,173,181,1) 57.27%, rgba(232,232,232,1) 74.31%, rgba(103,103,103,1) 96.34%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#949494",endColorstr="#676767",GradientType=1);
    box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;
    border-radius: 37.5px;

    margin: 1rem!important;
    width: 15rem;
    @include primary-text(1.5rem, $dark-grey, 400) ;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    p{
      margin: 0;
    }
  }
}

.rrssContainer{
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  .rrssImg{
    background-color: $grey;
    color: $dark-grey;
    border-radius: 50px;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin: 0 1rem;
    cursor: pointer;
  }
  .rrssImg:hover{
    box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;
    color: #0038FF;
  }
}

.system{
  width: 3rem;
}

.wDaddy{
  height: 3rem;
}

.banner { 
  color:#FFF;
  background-color:#0038FF !important;
  font-size:26px !important;
  font-family: 'Oswald', sans-serif;
  font-weight: 300 !important;
  margin: 30px 0 10px 0 !important;
}

.divBut{
  a:hover{
    border-bottom: none!important;
  }
}
.logoCont{
  display: flex!important;
  align-items: center;
  span{
    @include primary-text(1.5rem, white, 400) ;
  }
}

@media screen and(max-width: 768px) {
  .home{
    height: 100%;
    background-position: 60%;
  }
  .logoCont{
    margin-top: 1rem!important;
  }   
  .wDaddy{
    margin-top: 1rem!important;
  }
   
}
@media screen and(max-width: 500px) {
  .home{
 .logoImg{
    width: 22rem!important;
    margin-top: 3rem
  } }
    
}
@media screen and(max-width: 380px) {
  .home{
 .logoImg{
    width: 19rem!important;
  } }
    
}
@media screen and(max-width: 768px) {

  .buttonContainer{
    margin-bottom: 3rem;
  }

}

