@import "../css/general_styles.scss";

.ant-btn-primary {
  background: $primary-color;
  border: none;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  @include primary-text($body, white, 400);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #ac131f;
  border-color: transparent;
}

.ant-layout {
  background: transparent;
  .ant-layout-content {
    padding: 0 !important;
    height: auto;
  }
}

.footer {
  background: $black;
  display: flex;
  flex-direction: column;
  .top {
    padding: 1em 0 2em 0;
    display: flex;
    .footerLogo {
      width: 15%;
      display: flex;
      .map {
        p {
          @include primary-text($label, $white, normal);
          text-transform: uppercase;
          letter-spacing: 0.2;
          margin: 0 1em;
        }
      }
    }
    .contact {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .ant-btn-secondary-button {
        width: 175px;
        justify-content: center;
        border: none;
        background: $green;
        display: flex;
        align-items: center;
        @include primary-text($label, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        img {
          margin-right: 0.5em;
        }
      }
      p {
        @include primary-text($label, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        margin-bottom: 1em;
      }
    }
    .question {
      width: 50%;
      p {
        @include primary-text($label, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        margin: 0;
      }
    }
  }
  .bottom {
    padding-top: 1em;
    border-top: 1px solid $white;
    display: flex;
    @include primary-text($label, $white, normal);
    letter-spacing: 0.2em;
    opacity: 0.5;
  }
}

@media screen and (max-width: 992px) {
  .footer .top {
    flex-wrap: wrap;
    .footerLogo {
      width: 50%;
    }
    .contact {
      width: 50%;
    }
    .question {
      width: 100%;
      margin: 1em 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    .top {
      .footerLogo {
        justify-content: flex-start;
        width: 100%;
      }
      .contact {
        justify-content: flex-start;
        width: 100%;
        margin: 1em 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  h2{
    font-size: 2rem!important;
  }
}
.ant-menu{
  background-color: transparent!important;
  display: flex;
  justify-content: center;
}

.ant-layout-header {
  background: transparent;
  padding: 0!important;
  @include primary-text($body, $black, 400);
  text-transform: uppercase;
}

.Nav {
  background-color: transparent;
  .ant-menu-horizontal {
    border: none;
    color: white;
  }

}

.ant-menu-submenu-popup{
  background-color: white;
  @include primary-text($body, $black, 400);
}

.ant-menu-submenu-popup > .ant-menu{
  display: block!important;
}
.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,{
  color: #0038ff!important;
}

.ant-menu-item-selected::after{
  border-bottom: #0038ff!important;
  border: 2px solid;
}

.ant-menu-item:hover::after{
  border-bottom: #0038ff!important;
  border: 2px solid;
}
.menuLinks{
  a{
    color: white !important;
  }
}
a{
  color: #353535!important;
  transition: none;
}
a:active, .ant-menu-item:active{
  background-color: transparent!important;
}
.ant-menu-item-selected{
  color: #0038ff!important;
}
.ant-menu-item-active{
  color: white!important;
}
.scroll{
  background-color: #0d1019;
}

.ant-menu-submenu-title:hover{
  color: #0038ff !important;
}
.ant-menu-submenu:hover{
  color: #0038ff !important;
}
.ant-menu-submenu:active{
  color: #0038ff !important;
}

.ant-menu-submenu:after{
  border-bottom: 2px solid #0038ff !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color: #FFFFFF !important;
}

.test{
background-color: red;
height: 20px;
width: 20px;
position: absolute;
}