@import "../../css/_general_styles.scss";

.robots {
  background: #0d1019;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: screen;
  h2 {
    @include titles(2.5rem, $white, 400);
    text-align: center;
  }
  padding: 3rem 2rem;
}

.buttonBuy {
  cursor: pointer;
  background: linear-gradient(
    272.43deg,
    #949494 4.67%,
    #e0e0e0 37.33%,
    #a9adb5 57.27%,
    #e8e8e8 74.31%,
    #676767 96.34%
  );
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.78);
  border-radius: 37.5px;
  margin: 0 1rem !important;
  width: 15rem;
  @include primary-text(1.5rem, $dark-grey, 400);
  margin-top: 2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  p {
    margin: 0;
  }
}

.buttonBuy:hover {
  background: linear-gradient(
    272.43deg,
    #949494 4.67%,
    #e0e0e0 37.33%,
    #a9adb5 57.27%,
    #e8e8e8 74.31%,
    #676767 96.34%
  );
  box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038ff;
  border-radius: 37.5px;

  margin: 0 1rem !important;
  width: 15rem;
  @include primary-text(1.5rem, $dark-grey, 400);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.5rem;
  p {
    margin: 0;
  }
}
.titlesContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  img{
      width: 15rem;
      padding-bottom: 1rem;
  }
}
.robotsName {
  @include primary-text(1.5rem, $white, 400);
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.robotsSerie {
  @include primary-text(1rem, $white, 400);
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.robotsDescription {
  @include primary-text(1rem, $white, 400);
}
.robotsDescription2 {
  @include primary-text(1rem, $white, 400);
  margin-bottom: 2rem;
}

.itemsCarrousel {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.carousel .slide{
    img{
        width: 27rem!important;
    }
    text-align: left!important;
}

.descriptionContainer {
  margin-bottom: 2rem;
}

.nft {
  width: 25rem;
}

.carrouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.lineTitle {
  background: linear-gradient(
    89.54deg,
    rgba(26, 41, 70, 0) -1.53%,
    #95b9ff 47.83%,
    rgba(26, 41, 70, 0) 95.17%
  );
  height: 0.2rem;
  width: 200px;
}
.lineContainer {
  display: flex;
  justify-content: center;
}

.textContainerNft{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 6rem;
}

.slider-wrapper{
    height: 100%!important;
}

.carousel-root{
    width: 100%!important;
}

@media screen and (max-width: 425px) {
  .robots {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .textContainerNft{
    padding: 0 2rem;
  }
}

@media screen and (min-width: 1920px) {
  .carousel .slide{
    img{
        width: 45rem!important;
    }
}
}
@media screen and (max-width: 768px) {
  .robots {
    height: 100%!important;
}
.buttonBuy{
  align-self: center;
}
}
