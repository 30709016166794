@import "../../css/_general_styles.scss";

.roadmap{
    background: url("https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/binary.png") , #040e25;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode:screen;
    h2{
        @include titles(2.5rem, $white, 400) ;
        text-align: center;
    }
    padding: 3rem 2rem;
}

.phase{
    width: 25rem;
    margin:  2rem;
    background: linear-gradient(272.43deg, #949494 4.67%, #E0E0E0 37.33%, #A9ADB5 57.27%, #E8E8E8 74.31%, #676767 96.34%);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.78);
    border-radius: 37.5px;
    box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;

    .roadmapTitle{
        @include primary-text(1.5rem, #061128, 500) ;
        margin-bottom: 2rem
    }
    .roadmapSpan{
        @include primary-text(1rem, #061128, 400) ;
    }
    text-transform: uppercase;
    display: flex;
    justify-content:left;
    flex-direction: column;
    padding: 2rem;
}
.titlesContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 15rem;
        padding-bottom: 1rem
;
    }
}
.phase:hover{
    width: 25rem;
    margin:  2rem;
    background: linear-gradient(272.43deg, #949494 4.67%, #E0E0E0 37.33%, #A9ADB5 57.27%, #E8E8E8 74.31%, #676767 96.34%);
    //box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;
    border-radius: 37.5px;

 
    .roadmapTitle{
        @include primary-text(1.5rem, #061128, 500) ;
    }
    .roadmapSpan{
        @include primary-text(1rem, #061128, 400) ;
    }
    text-transform: uppercase;
    display: flex;
    justify-content:left;
    flex-direction: column;
    padding: 2rem;
}

.phaseContainer{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
}

@media screen and (max-width: 1991px) {
    .phaseContainer:last-child{
        margin-top: 0rem!important;
    }
  }
@media screen and (max-width: 1024px) {
    .roadmap{
        padding: 3rem 0rem;
    }
  }