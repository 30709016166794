@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}

@font-face {
  font-family: "Ethnocentric";
  src: url("../src/fonts/Ethnocentric.TTF") format('truetype');

}