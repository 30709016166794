@import "../../css/_general_styles.scss";

.tokenomics{
    background-image: url("https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/tokenomics.png");
    background: url("https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/tokenomics.png") , linear-gradient(180deg, #0D1019 0%, #061128 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode:luminosity;
    h2{
        @include titles(2.5rem, $white, 400) ;
        text-align: center;
    }
    padding: 3rem 2rem;
}
.titlesContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 15rem;
        padding-bottom: 1rem;
    }
  }
.lineTitle{
    background: linear-gradient(89.54deg, rgba(26, 41, 70, 0) -1.53%, #95B9FF 47.83%, rgba(26, 41, 70, 0) 95.17%);
    height: .2rem;
    width: 200px;
}
.lineContainer{
    display: flex;
    justify-content: center;
}

.tokenomicsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
}

.spanContainer{
    display: flex;
    align-items: center;
}
.textContainerToken{
    margin: 2rem
}

.tokenomicsSpan{
    @include primary-text(1.5rem, $white, 400) ;
}
.tokenomicsSpanTitle{
    @include primary-text(2rem, $white, 400) ;
}

.dot1{
    background-color: #204F73;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot2{
    background-color: #F4F0FD;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot3{
    background-color: #A5C1D6;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot4{
    background-color: #CAE4F8;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot5{
    background-color: #77A7CB;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot6{
    background-color: #4982AD;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}
.dot7{
    background-color: #2F6A96;
    border-radius: 50px;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
}

.tokenomicsSpanTitleContainer{
    margin-bottom: 1rem;
}
.chart{
    width: 22rem;
}

.chartContainer{
    margin: 2rem;
}

@media screen and (max-width: 850px) {
    .chart{
        width: 18rem;
    }
    .textContainerToken{
        margin: 0rem!important;
    }
    .tokenomicsContainer{
        margin: 3rem 0;
    }
    .nftContainer img {
        width: 100%;
        padding: 0;
    }
    .tokenomics{
        height: 100%;
        margin-left: auto;
        margin-right:auto
    }
}