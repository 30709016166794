@import "../../css/_general_styles.scss";

.partnership {
  background:  url("https://elasticbeanstalk-us-east-2-692605901339.s3.us-east-2.amazonaws.com/img/img/binary.png"), linear-gradient(180deg, #061128 0%, #000000 100%);
  padding-top: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logoImg{
    width: 30rem;
  }
  .badgesImg{
    margin: 1rem 1rem;
  }
}

.rrssContainer{
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  .rrssImg{
    background-color: $grey;
    color: $dark-grey;
    border-radius: 50px;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin: 0 1rem;
    cursor: pointer;
  }
  .rrssImg:hover{
    box-shadow: 0px 4px 47px rgba(5, 0, 255, 0.25), 0px 4px 47px #0038FF;
    color: #0038FF;
  }
}

.system{
  width: 3rem;
}

.wDaddy{
  height: 3rem;
}

.divBut{
  a:hover{
    border-bottom: none!important;
  }
}
.logoCont{
  display: flex!important;
  align-items: center;
  span{
    @include primary-text(1.5rem, white, 400) ;
  }
}

@media screen and(max-width: 768px) {
  .partnership{
    height: 100%;
  }
  .logoCont{
    margin-top: 1rem!important;
  }   
  .wDaddy{
    margin-top: 1rem!important;
  }
   
}
@media screen and(max-width: 500px) {
  .partnership{
 .logoImg{
    width: 22rem!important;
    margin-top: 3rem
  } }
    
}
@media screen and(max-width: 380px) {
  .partnership{
 .logoImg{
    width: 19rem!important;
  } }
    
}
@media screen and(max-width: 768px) {

  .buttonContainer{
    margin-bottom: 3rem;
  }

}

.titlesContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 15rem;
        padding-bottom: 1rem;
    }
}
.linksTeam{
    margin-right: .5rem;
}

@media screen and (min-width: '1920px')  {
    .titlesContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        img{
            width: 25rem;
            padding-bottom: 1rem;
        }
    }
}