@import "../../css/_general_styles.scss";

.cpu {  
  background: #0d1019;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: screen;
  h2 {
    @include titles(2.5rem, $white, 400);
    text-align: center;
  }
  padding: 3rem 2rem;
}

.carrouselContainer{
  margin-top: 3rem
}

.titlesContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  img{
      width: 15rem;
      padding-bottom: 1rem;
  }
}

@media screen and (max-width: 425px) {
    .cpu {
        padding-left: 0rem;
        padding-right: 0rem;
      }
      .textContainerNft{
        padding: 0 2rem;
      }
  }
  
  @media screen and (max-width: 768px) {
    .cpu{
      height: 100%!important;
  }
  .buttonBuy{
    align-self: center;
  }
  }

  @media screen and (min-width: 1920px) {
    .carousel .slide{
      img{
          width: 45rem!important;
      }
  }
  }
  

  